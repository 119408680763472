import React from 'react';
import '../styles/navbar.css';
import { Link } from 'react-router-dom';

export const NavBar = () => {
  return (
    <nav>
      <h2>ADMIN <span>.system</span></h2>
      <menu>
        <Link to='/'>Balance</Link>
        <Link to='/new-data'>New Data</Link>
        <Link to='/calculate'>Calculate</Link>
      </menu>
    </nav>
  )
}
