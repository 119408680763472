import { BlockchainNumber, DatesPeriod, RoundNumber } from './Functions.jsx';
import moment from 'moment';
import 'moment-timezone';

import DataContributions from "../assets/json/dataContributions.json";

const contractId = process.env.REACT_APP_CONTRACT;

function ClassifyData(data) {
  const signIn = [];
  const invest = [];
  data.forEach(
    (e) => {
      if (e[5] === 'Sign In') signIn.push(e);
      if (e[5] === 'Invest') invest.push(e);
    }
  )
  return { signIn, invest }
}

async function SignIn(data, contract) {
  try {
    const singIn = [
      [
        `hash`,
        `block`,
        `wallet`,
        `inviter`,
        `signInDate`,
        `amount`,
        `gas`,
        `contract`,
        `status`
      ],
    ];

    const amount = parseInt(process.env.REACT_APP_AMOUNT);
    let total = 0;
    let records = 0;

    for (const e of data) {
      try {
        const hasPaid = await contract.hasPaid(e[2]);

        if (hasPaid) {
          const inviter = await contract.getInviterWallet(e[2]);

          singIn.push([
            e[0],
            e[1],
            e[2],
            inviter,
            e[3],
            amount,
            e[4],
            contractId,
            hasPaid,
          ]);
          records += 1;
          total += amount;
        }
      } catch (error) {
        console.error(`Error processing data for ${e[2]}:`, error);
      }
    }

    return { items: singIn, records, total };
  } catch (error) {
    console.error('Error in SignIn function:', error);
    throw error;
  }
}

async function Invest(data, contract) {
  try {
    const invest = [
      [
        `hash`,
        `block`,
        `wallet`,
        `amount`,
        `date`,
        `startDate`,
        `endDate`,
        `#Payments`,
        `payment`,
        `name`,
        `gas`,
        `contract`,
        `status`,
      ],
    ];

    let total = 0;
    let records = 0;

    for (const e of data) {
      try {
        const investmentDetails = await contract.getInvestmentDetails(e[2]);

        if (investmentDetails.length > 0) {
          let amount = 0;
          let dateStartTemp = "";
          let dateFinalDate = "";
          let lockPeriod = 0;
          let name = "";
          let payment = 0;

          for (const x of investmentDetails) {
            const item = x;
            const dateContributionHex = item.startTime["_hex"];
            const dateContributionNumber = Number(dateContributionHex);
            const dateContributionDate = moment.unix(dateContributionNumber).utcOffset('+0000').format("YYYY-MM-DD HH:mm:ss");

            if (dateContributionDate === e[3]) {
              const amountHex = item.amount["_hex"];
              amount = BlockchainNumber(amountHex);
              lockPeriod = Number(item.lockPeriodMonths["_hex"]);
              name = item.name;
              const res = DataContributions.find(e => e.months === lockPeriod);
              const result = DatesPeriod(dateContributionDate, lockPeriod);
              dateStartTemp = result.dateStartTemp;
              dateFinalDate = result.dateFinalDate;
              if (lockPeriod === 24) {
                lockPeriod = 1;
              }
              payment = RoundNumber(amount * res.percentage);
            }
          }

          invest.push([
            e[0],
            e[1],
            e[2],
            amount,
            e[3],
            dateStartTemp,
            dateFinalDate,
            lockPeriod,
            payment,
            name,
            e[4],
            contractId,
            true,
          ]);
          records += 1;
          total += amount;
        }
      } catch (error) {
        console.error(`Error processing data for ${e[2]}:`, error);
      }
    }

    return { items: invest, records, total };
  } catch (error) {
    console.error('Error in Invest function:', error);
    throw error;
  }
}

const Summary = (SignIn, Invest) => {
  try {
    const summary = [[
      `records`,
      `signIn`,
      `signIn amount`,
      `invest`,
      `invest amount`
    ]];
    summary.push([
      SignIn["records"] + Invest["records"],
      SignIn["records"],
      SignIn["total"],
      Invest["records"],
      Invest["total"],
    ])
    return summary
  } catch (error) {
    return error;
  }
}

export {
  ClassifyData,
  SignIn,
  Invest,
  Summary
};