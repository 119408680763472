import moment from 'moment';

// This feature cuts the wallet
function TrimWallet(wallet, short) {
  const LENGTH = wallet ? wallet.length : 0;

  if (LENGTH > 30) {
    if (short !== false)
      return `${wallet.slice(0, 5)}...${wallet.slice(-4)}`;
    else
      return `${wallet.slice(0, 8)}...${wallet.slice(-8)}`;
  }

  return wallet;
}

// This feature cuts the wallet
function CellTable(text) {
  const LENGTH = text ? text.length : 0;

  if (text === false) {
    return 'False';
  } else if (text === true) {
    return 'True';
  } else if (LENGTH > 30 && !text.includes(' ')) {
    // Si la longitud es mayor a 30 y no contiene espacios
    return `${text.slice(0, 5)}...${text.slice(-4)}`;
  }

  return text;
}

// Format the number and separate the thousands by comma
function RoundNumber(number) {
  const m = Number((Math.abs(number) * 100).toPrecision(25));
  const round = (Math.round(m) / 100) * Math.sign(number);

  return round;
};

// Format the number and separate the thousands by comma
function NumberFormat(number, money) {
  const round = RoundNumber(number);
  const str = round.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const res = str.join(".")

  if (money)
    return `$${res} USD`;

  return res;
};

// Dates period
function DatesPeriod(dateContribution, lockPeriod) {
  let month = moment(dateContribution).month();
  let year = moment(dateContribution).year();
  let day = moment(dateContribution).date();
  let dateStartTemp = moment().year(year).month(month).date(1);
  let addMonth = 1

  if (day > 18) addMonth = 2;

  dateStartTemp = moment(dateStartTemp).add(addMonth, 'months');
  dateStartTemp = moment(dateStartTemp).format("YYYY/MM/DD");

  let dateFinalDate = moment(dateStartTemp).add(lockPeriod, 'months');
  dateFinalDate = moment(dateFinalDate).format("YYYY/MM/DD");

  let diffTotal = moment(dateFinalDate).diff(moment(dateStartTemp), 'months');
  let today = moment();
  let diffToday = moment(today).diff(moment(dateStartTemp), 'months');

  let difRes = diffTotal - diffToday;

  return { difRes, diffToday, dateStartTemp, dateFinalDate }
}

// Transform a number to bignumber for blockchain
function NumberBlockchain(number) {
  const fractions = String(process.env.REACT_APP_FRACTIONS).substring(1);
  return `${number}${fractions}`;
}

// Transform a bignumber to number for blockchain
function BlockchainNumber(value) {
  const number = Number(value) / Number(process.env.REACT_APP_FRACTIONS);
  const roundNumber = RoundNumber(number);
  return roundNumber;
}

// Delete the desired column
function DeleteColumn(array, columnIndex) {
  return array.map((fila) => fila.filter((_, index) => index !== columnIndex));
}

// Delete the desired row
function DeleteRow(array, rowIndex) {
  return array.filter((_, index) => index !== rowIndex);
}

export {
  BlockchainNumber,
  DatesPeriod,
  DeleteColumn,
  DeleteRow,
  NumberBlockchain,
  NumberFormat,
  RoundNumber,
  TrimWallet,
  CellTable,
}