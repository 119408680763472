import React from 'react';
import { CellTable } from '../widgets/Functions';
import '../styles/tableexcel.css';

const TableExcel = ({ tableData }) => {
  const nA = tableData === null ? 0 : tableData[0].length;
  const wA = nA === 0 ? 100 : 100 / nA;

  return (
    <div style={{ height: '70%', overflow: 'hidden' }}>
      {tableData && (
        <div style={{ height: '100%' }}>
          <div style={{ height: '8%' }}>
            <table className="custom-table table-striped">
              <thead>
                <tr>
                  {tableData[0].map((header, index) => (
                    <th style={{ width: `${wA}%` }} key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
            </table>
          </div>
          <div className='table_body'>
            <table className="custom-table table-striped">
              <tbody>
                {tableData.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td style={{ width: `${wA}%` }} key={cellIndex}>{CellTable(cell)}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default TableExcel;