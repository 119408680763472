import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import swal from 'sweetalert';
import TableApp from '../components/TableExcel';
import ABI from '../assets/json/abi_black8.json';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import '../styles/newdata.css';

const contractAddress = process.env.REACT_APP_CONTRACT;
const apiBackEnd = process.env.REACT_APP_API;
const chain = Number(process.env.REACT_APP_CHAIN);
const chainHex = process.env.REACT_APP_CHAIN_HEX;
const API_SEARCH_BALANCE = `${apiBackEnd}/backend/balance/searchBalanceDates.php`;
const WALLETS = [
  process.env.REACT_APP_WALLET_OWNER,
  process.env.REACT_APP_WALLET_SUPPORT
]

const YearMonthPicker = ({ selectedDate, onChange }) => {
  const [startDate, setStartDate] = useState(selectedDate);

  const CustomInput = ({ value, onClick }) => (
    <button className="custom-input" onClick={onClick}>
      {value}
    </button>
  );

  const handleDateChange = (date) => {
    setStartDate(date);
    onChange(date);
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={handleDateChange}
      dateFormat="yyyy/MM"
      showMonthYearPicker
      customInput={<CustomInput />}
    />
  );
};

function Balance() {
  const [, setProvider] = useState(null);
  const [, setAccount] = useState('');
  const [contract, setContract] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [process, setProcess] = useState("Loanding");
  const [tableData, setTableData] = useState(null);
  const [buttons, setButtons] = useState(false);
  const [payments, setPayments] = useState(null);
  const [balance, setBalance] = useState(null);

  console.log(WALLETS);

  useEffect(() => {
    async function loadBlockchainData() {
      if (window.ethereum) {
        try {
          handleOpenBackdrop("Login - metamask");
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          setProvider(provider);
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const accounts = await provider.listAccounts();
          setAccount(accounts[0]);
          if (WALLETS.includes(accounts[0])) {
            const network = await provider.getNetwork();
            if (network.chainId === chain) {
              const contractInstance = new ethers.Contract(contractAddress, ABI, provider.getSigner());
              setContract(contractInstance);
              handleCloseBackdrop();
            } else {
              handleOpenBackdrop("Change network");
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: chainHex }],
              });
            }
            window.ethereum.on('chainChanged', () => window.location.reload());
          }
          else {
            handleOpenBackdrop("Unauthorized account");
          }
          window.ethereum.on('accountsChanged', () => window.location.reload());
        } catch (error) {
        }
      } else {
        handleCloseBackdrop();
        swal("Error!", `Metamask is not installed, you will need to install it before proceeding. Please download and install Metamask from the official website. Once installed, you can continue with the registration process.`, "error");
      }
    }

    loadBlockchainData();
  }, []);

  const handleData = (data, type) => {
    if (type === "Balance") {
      const e = data["Balance"];
      const array = [[
        "ID Balance",
        "Start Date",
        "End Date",
        "Contributions",
        "Amount contributions",
        "Payment",
        "Amount Payment",
        "SingIn",
        "amount SingIn",
      ],
      [
        e.balance,
        e.startDate,
        e.endDate,
        e.numberContributions,
        e.amountContributions,
        e.numberPayment,
        e.amountPayment,
        e.numberSingIn,
        e.amountSingIn,
      ]]
      setTableData(array);
    } else if (type === "Payments") {
      const array = [[
        "ID",
        "ID Balance",
        "Contribution / Rewards",
        "Type",
        "Wallet",
        "Amount",
        "Number Payment",
      ]]
      data["Payments"].forEach(
        e =>
          array.push([
            e.id,
            e.idBalance,
            e.hashContribution,
            e.type,
            e.wallet,
            e.amount,
            e.numberPayment,
          ]))
      setTableData(array);
    } else if (type === "Contributions") {
      const array = [[
        "Hash",
        "Block",
        "Wallet",
        "Date",
        "Start Date",
        "End Date",
        "Amount",
        "Payment",
        "Number Payments",
        "Name",
        "Gas",
        "Contract",
        "Status",
      ]]
      data["Contributions"].forEach(
        e =>
          array.push([
            e.hash,
            e.block,
            e.wallet,
            e.date,
            e.startDate,
            e.endDate,
            e.amount,
            e.payment,
            e.numberPayments,
            e.name,
            e.gas,
            e.contract,
            e.status,
          ]))
      setTableData(array);
    } else if (type === "Wallets") {
      const array = [[
        "hash",
        "wallet",
        "signInDate",
        "inviter",
        "inviter_2",
        "inviter_3",
        "amount",
        "block",
        "contract",
        "gas",
        "status",
      ]]
      data["Wallets"].forEach(
        e =>
          array.push([
            e.hash,
            e.wallet,
            e.signInDate,
            e.inviter,
            e.inviter_2,
            e.inviter_3,
            e.amount,
            e.block,
            e.contract,
            e.gas,
            e.status,
          ]))
      setTableData(array);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    searchBalance(date);
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleOpenBackdrop = (process) => {
    setProcess(process);
    setOpenBackdrop(true);
  };

  const searchBalance = async (date) => {
    try {
      const data = {
        year: date.getFullYear(),
        month: date.getMonth() + 1
      }
      const resultBalance = await handleFetch(API_SEARCH_BALANCE, data, 'Balance');
      setBalance(resultBalance);
      setButtons(true);
      handleCloseBackdrop();
      handleData(resultBalance, "Balance");
    } catch (error) {
      setButtons(false);
    }
  }

  const handleFetch = async (endpoint, data, type) => {
    handleOpenBackdrop(`Search ${type}`);

    const requestOptions = {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(data),
      redirect: 'follow',
    };

    try {
      const response = await fetch(endpoint, requestOptions);
      const result = await response.json();
      return result;
    } catch (error) {
      console.error(`Error ${type} data:`, error);
      return [];
    }
  }

  return (
    <div className="calculatepage_main">
      <h2 className="calculatepage_h2">Balance:</h2>
      <div className="calculatepage_header">
        <YearMonthPicker selectedDate={selectedDate} onChange={handleDateChange} />
      </div>
      <TableApp tableData={tableData} />
      {buttons && (
        <div className="calculatepage_buttons">
          <button onClick={() => handleData(balance, "Balance")}> Balance </button>
          <button onClick={() => handleData(balance, "Wallets")}> Sign In </button>
          <button onClick={() => handleData(balance, "Contributions")}> Contributions </button>
          <button onClick={() => handleData(balance, "Payments")}> Payments </button>
        </div>
      )}
      <Backdrop
        className="backdrop"
        style={{
          backgroundColor: 'rgb(0, 0, 0, 1)'
        }}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <div>
          <CircularProgress
            style={{
              width: "60px",
              height: "60px",
              color: "rgb(251, 177, 61)"
            }}
            color="inherit"
          />
          <p className="backdrop_process">
            {process}
          </p>
        </div>
      </Backdrop>
    </div>
  )
}

export default Balance;
