import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';

const ExcelReader = ({ setData }) => {

  const selectData = (data) => {
    let arrayTemp = [];
    arrayTemp.push(
      [
        'Hash',
        'Block',
        'Wallet',
        'DateTime (UTC)',
        'TxnFee(USD)',
        'Method',
      ]
    );
    data.forEach(
      e => {
        if ((e[15] === "Sign In" || e[15] === "Invest") && e[14] === "")
          arrayTemp.push([
            e[0],
            e[1],
            e[4],
            e[3],
            e[11],
            e[15],
          ]);
      }
    )
    setData(arrayTemp);
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        const binaryStr = reader.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });

        // Supongamos que el archivo Excel tiene una sola hoja
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Obtener los datos como un array de objetos
        const data = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          raw: true,
          defval: '',
        });
        selectData(data);
      };

      reader.readAsBinaryString(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.xls, .xlsx, .csv',
  });

  return (
    <div style={styles}>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>Drag and drop Excel files here, or click to select files</p>
      </div>
    </div>
  );
};

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  textAlign: 'center',
  cursor: 'pointer',
  height: '90%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const styles = {
  height: '10%',
};

export default ExcelReader;
