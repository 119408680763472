import React from 'react';
import { Routes, Route } from "react-router-dom"
import { NavBar } from './components/NavBar';

import NewData from './pages/NewData';
import Calculate from './pages/Calculate';

import './styles/app.css';
import Balance from './pages/Balance';

function App() {
  return (
    <div className="App">
      <NavBar />
      <div className="container_page">
        <Routes>
          <Route path="new-data" element={<NewData />} />
          <Route path="calculate" element={<Calculate />} />
          <Route path="" element={<Balance />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
