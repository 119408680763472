import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import swal from 'sweetalert';
import { ClassifyData, SignIn, Invest, Summary } from '../widgets/ReadData.jsx';
import ExcelReader from '../components/ExcelReader';
import TableApp from '../components/TableExcel';
import ABI from '../assets/json/abi_black8.json';

import '../styles/newdata.css';

const contractAddress = process.env.REACT_APP_CONTRACT;
const apiBackEnd = process.env.REACT_APP_API;
const chain = Number(process.env.REACT_APP_CHAIN);
const chainHex = process.env.REACT_APP_CHAIN_HEX;
const API_SIGNIN_ENDPOINT = `${apiBackEnd}/backend/wallets/insertWallet.php`;
const API_INVEST_ENDPOINT = `${apiBackEnd}/backend/contributions/insertContributions.php`;
const API_REWARDS_ENDPOINT = `${apiBackEnd}/backend/rewards/insertRewards.php`;
const WALLETS = [
  process.env.REACT_APP_WALLET_OWNER,
  process.env.REACT_APP_WALLET_SUPPORT
]

function NewData() {
  const [, setProvider] = useState(null);
  const [, setAccount] = useState('');
  const [contract, setContract] = useState(null);
  const [buttons, setButtons] = useState(false);
  const [calculate, setCalculate] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [signInData, setSignInData] = useState(null);
  const [investData, setInvestData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [process, setProcess] = useState("Loanding");

  useEffect(() => {
    async function loadBlockchainData() {
      if (window.ethereum) {
        try {
          handleOpenBackdrop("Login - metamask");
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          setProvider(provider);
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const accounts = await provider.listAccounts();
          setAccount(accounts[0]);
          if (WALLETS.includes(accounts[0])) {
            const network = await provider.getNetwork();
            if (network.chainId === chain) {
              const contractInstance = new ethers.Contract(contractAddress, ABI, provider.getSigner());
              setContract(contractInstance);
              handleCloseBackdrop();
            } else {
              handleOpenBackdrop("Change network");
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: chainHex }],
              });
            }
            window.ethereum.on('chainChanged', () => window.location.reload());
          }
          else {
            handleOpenBackdrop("Unauthorized account");
          }
          window.ethereum.on('accountsChanged', () => window.location.reload());
        } catch (error) {
        }
      } else {
        handleCloseBackdrop();
        swal("Error!", `Metamask is not installed, you will need to install it before proceeding. Please download and install Metamask from the official website. Once installed, you can continue with the registration process.`, "error");
      }
    }

    loadBlockchainData();
  }, []);

  const handleData = (data, type) => {
    setTableData(data);
    if (type === 'excel') {
      setExcelData(data);
      setButtons(false);
      setCalculate(true);
    }
  };

  const readData = async (value) => {
    try {
      setButtons(false);
      handleOpenBackdrop("Clasificando datos");
      const { invest, signIn } = await ClassifyData(value);
      handleOpenBackdrop("Processing registrations");
      const signInResult = await SignIn(signIn, contract);
      setSignInData(signInResult["items"]);
      handleOpenBackdrop("Processing contributions");
      const contributions = await Invest(invest, contract);
      setInvestData(contributions["items"]);
      handleOpenBackdrop("Processing summary");
      const summary = await Summary(signInResult, contributions);
      setSummaryData(summary);
      handleCloseBackdrop();
      setCalculate(true);
      setButtons(true);
    } catch (error) {
      setButtons(false);
      setCalculate(true);
    }
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleOpenBackdrop = (process) => {
    setProcess(process);
    setOpenBackdrop(true);
  };

  const insertData = async () => {
    try {
      const signInDB =
        prepareData(
          signInData.slice(1),
          [
            "hash",
            "block",
            "wallet",
            "inviter",
            "signInDate",
            "amount",
            "gas",
            "contract",
            "status"
          ]
        );
      const investDB =
        prepareData(
          investData.slice(1),
          [
            "hash",
            "block",
            "wallet",
            "amount",
            "date",
            "startDate",
            "endDate",
            "numberPayments",
            "payment",
            "name",
            "gas",
            "contract",
            "status"
          ]
        );
      const signInRes = await sendData(API_SIGNIN_ENDPOINT, signInDB, 'signIn');
      const investRes = await sendData(API_INVEST_ENDPOINT, investDB, 'invest');
      const rewardRes = await sendData(API_REWARDS_ENDPOINT, investDB, 'rewards');

      const res = [
        ['Type', 'Wallet/Hash', 'Status', 'Error'],
        ...processResults(signInRes, 'signIn'),
        ...processResults(investRes, 'invest'),
        ...processResults(rewardRes, 'rewards'),
      ];

      setTableData(res);
    } catch (error) {
      console.error(error);
    } finally {
      handleCloseBackdrop();
    }
  };

  const prepareData = (data, keys) => {
    return data.map(e => {
      return Object.fromEntries(keys.map((key, index) => [key, e[index]]));
    });
  };

  const sendData = async (endpoint, data, type) => {
    handleOpenBackdrop(`Sending ${type}`);

    const requestOptions = {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(data),
      redirect: 'follow',
    };

    try {
      const response = await fetch(endpoint, requestOptions);
      const result = await response.json();
      return result;
    } catch (error) {
      console.error(`Error sending ${type} data:`, error);
      return [];
    }
  };

  const processResults = (results, type) => {
    return results.map(e => [type, e['hash'] || e['wallet'], e['status'], e['error']]);
  };

  return (
    <div className="calculatepage_main">
      <ExcelReader
        setData={(data) => handleData(data, 'excel')}
      />
      <div className="calculatepage_header">
        <h2 className="calculatepage_h2">New Data:</h2>
        {calculate && (
          <button onClick={() => readData(excelData)}>
            Read
          </button>
        )}
      </div>
      <TableApp tableData={tableData} />
      {buttons && (
        <div className="calculatepage_buttons">
          <button onClick={() => handleData(excelData)}> Data </button>
          <button onClick={() => handleData(summaryData)}> Summary </button>
          <button onClick={() => handleData(signInData)}> SignIn </button>
          <button onClick={() => handleData(investData)}> Invest </button>
          <button onClick={() => insertData()}> Save </button>
        </div>
      )}
      <Backdrop
        className="backdrop"
        style={{
          backgroundColor: 'rgb(0, 0, 0, 1)'
        }}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <div>
          <CircularProgress
            style={{
              width: "60px",
              height: "60px",
              color: "rgb(251, 177, 61)"
            }}
            color="inherit"
          />
          <p className="backdrop_process">
            {process}
          </p>
        </div>
      </Backdrop>
    </div>
  )
}

export default NewData;
